<template>
  <v-container>
    <v-dialog
      @click:outside="fecharModal()"
      v-model="show"
      min-width="500"
      max-width="1500"
    >
      <v-card>
        <v-card-title>
          <h3>{{ titulo }}</h3>
          <v-icon @click="fecharModal()">fa-times</v-icon>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h3>Importante!</h3>
              <v-divider class="mt-2"></v-divider>
            </v-col>
            <v-col cols="12">
              <ul
                v-for="(item, index) in listaTransporte"
                :key="index"
                class="text-justify"
              >
                <li>{{ item }}</li>
              </ul>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col>
                    <p class="text-left">
                      Selecione o tipo de alteração para o vale transporte:
                      <span style="color: red">*</span>
                    </p>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="isAlteracaoTrajeto"
                          label="Adesão/Alteração de trajeto"
                          @change="isReajusteValor = false"
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-checkbox
                          v-model="isReajusteValor"
                          label="Reajuste no valor da passagem"
                          @change="isAlteracaoTrajeto = false"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      label="Localidade"
                      v-model="vModel.localidade"
                      clearable
                      :menu-props="{ bottom: true, offsetY: true }"
                      outlined
                      @change="
                        vModel.localidade == 'Outros Estados' ||
                        !!vModel.localidade
                          ? (vModel.possuiCartao = null)
                          : null
                      "
                      hide-details="auto"
                      :rules="[
                        (v) => !!v || 'Obrigatório informar a localidade',
                      ]"
                      :items="['DF', 'Entorno', 'Outros Estados']"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    v-if="
                      vModel.localidade == 'DF' ||
                      vModel.localidade == 'Entorno'
                    "
                  >
                    <p class="text-left">
                      Possui cartão DFTrans? <span style="color: red">*</span>
                    </p>
                    <v-radio-group
                      v-model="vModel.possuiCartao"
                      @change="
                        !!vModel.possuiCartao ? (vModel.cartao = null) : null
                      "
                      :rules="[
                        (v) =>
                          vModel.localidade === 'Outros Estados' ||
                          !!v ||
                          'Obrigatório informar se possui cartão DFTrans',
                      ]"
                      inline
                    >
                      <v-radio label="Sim" value="sim"></v-radio>
                      <v-radio label="Não" value="nao"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="8" v-if="vModel.possuiCartao === 'sim'">
                    <p class="text-left">
                      Número do cartão DFTrans:
                      <span style="color: red">*</span>
                    </p>
                    <v-text-field
                      v-model="vModel.cartao"
                      label="Número do cartão"
                      outlined
                      clearable
                      hide-details="auto"
                      :rules="[
                        (v) =>
                          vModel.localidade === 'Outros Estados' ||
                          vModel.possuiCartao === 'nao' ||
                          !!v ||
                          'Obrigatório informar número do cartão DFTrans',
                      ]"
                    />
                  </v-col>
                </v-row>

                <v-row v-if="isAlteracaoTrajeto">
                  <v-col cols="12">
                    <p class="text-left">
                      Comprovante de residência:
                      <span style="color: red">*</span>
                    </p>
                    <v-file-input
                      v-model="vModel.comprovanteResidencia"
                      label="Anexe o comprovante de residência"
                      hide-details="auto"
                      prepend-icon
                      prepend-inner-icon="fas fa-paperclip"
                      outlined
                      clearable
                      :rules="[
                        (v) =>
                          !!v || 'Obrigatório anexar comprovante de residência',
                      ]"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row v-else-if="isReajusteValor">
                  <v-col cols="12">
                    <p class="text-left">
                      Informativo sobre reajuste:
                      <span style="color: red">*</span>
                    </p>
                    <v-file-input
                      v-model="vModel.informativoReajuste"
                      label="Anexe o informe sobre o reajuste da passagem"
                      hide-details="auto"
                      prepend-icon
                      prepend-inner-icon="fas fa-paperclip"
                      outlined
                      clearable
                      :rules="[
                        (v) =>
                          !!v ||
                          'Obrigatório anexar informativo sobre reajuste',
                      ]"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h4>Informações do Trajeto</h4>
                    <v-form ref="formTrajeto" lazy-validation>
                      <v-row class="row-trajeto">
                        <v-col cols="12" md="4">
                          Trajeto: <span style="color: red">*</span>
                          <v-text-field
                            v-model="vModel.trajeto"
                            outlined
                            clearable
                            hide-details="auto"
                            :rules="[
                              (v) => !!v || 'Obrigatório informar o trajeto',
                            ]"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          Linha e Empresa: <span style="color: red">*</span>
                          <v-text-field
                            v-model="vModel.linha"
                            outlined
                            clearable
                            hide-details="auto"
                            :rules="[
                              (v) =>
                                !!v || 'Obrigatório informar a linha e empresa',
                            ]"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          Valor: <span style="color: red">*</span>
                          <v-text-field
                            v-model="vModel.valor"
                            outlined
                            clearable
                            hide-details="auto"
                            :rules="[
                              (v) => !!v || 'Obrigatório informar o valor',
                            ]"
                            v-money="vModel.valor ? money : null"
                          />
                        </v-col>
                        <v-col cols="12" lg="4" offset-lg="8">
                          <v-btn
                            @click="adicionarTrajeto()"
                            class="botao-outlined-azul w-100"
                            >Adicionar Trajeto</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-data-table
                            :headers="headerTable"
                            :items="trajetos"
                            :hide-default-footer="true"
                            no-data-text="Nenhum trajeto informado!"
                            :style="
                              showMensagemTrajeto
                                ? 'border: 2px solid; border-color: #ff5252;border-radius: 15px 15px 0 0;'
                                : null
                            "
                          >
                            <template
                              v-for="header in headerTable.filter((header) =>
                                header.hasOwnProperty('formatter')
                              )"
                              v-slot:[`item.${header.value}`]="{ value }"
                            >
                              {{ header.formatter(value) }}
                            </template>

                            <template v-slot:[`item.acao`]="data">
                              <v-btn
                                @click="trajetos.splice(data.index, 1)"
                                id="btn-excluir"
                                >X</v-btn
                              >
                            </template>
                          </v-data-table>
                          <div
                            v-show="!showMensagemTrajeto"
                            id="mensagemTrajeto"
                          >
                            Obrigatório informar os dados do trajeto
                          </div>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

          <v-divider class="my-2"></v-divider>
        </v-card-text>

        <!-- Ações Modal -->
        <v-card-actions>
          <v-btn
            class="botao-secundario-par mr-2 largura-btn"
            @click="fecharModal()"
            >Fechar
          </v-btn>
          <v-btn
            @click="solicitar()"
            :disabled="!isAlteracaoTrajeto && !isReajusteValor"
            class="botao-principal mr-2 largura-btn"
            >Solicitar
          </v-btn>
        </v-card-actions>
      </v-card>

      <alerta
        v-on:fecharModal="fecharModal"
        :exibir="modal.exibir"
        :titulo="modal.titulo"
        :mensagem="modal.mensagem"
      />

      <loader :loader-primario="loader" />
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/services/Api.js";
import { removerMascara, formatCurrency } from "@/utils/formatCurrencyUtils.js";
import { TipoBeneficioEnum } from "@/enums/beneficio/TipoBeneficioEnum.js";
import alerta from "@/components/Alerta.vue";
import modalMixin from "@/mixins/modalMixin";
import { Money, VMoney } from "v-money";
import loader from "@/components/Loader.vue";
export default {
  directives: { money: VMoney },
  name: "formValeTransporte",
  components: {
    alerta,
    Money,
    loader,
  },
  mixins: [modalMixin],
  props: {
    value: Boolean,
    beneficio: Object,
    titulo: {
      type: String,
      default: "modal solicitar transporte",
    },
    isTroca: Boolean,
  },
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        suffix: "",
        masked: false,
      },
      listaTransporte: [
        "Colaboradores de Brasília recebem o vale transporte exclusivamente pelo cartão DFTrans que é de responsabilidade do colaborador.",
        "Caso o colaborador não possua o cartão, faremos a solicitação e em até 7 dias úteis o cartão estará disponível, neste caso o empregado deverá ir até um posto do BRB Mobilidade retirá-lo",
        "Colaboradores de outros Estados, recebem em dinheiro, sendo o valor depositado na mesma conta em que recebe o salário.",
        "Colaboradores que residem no entorno do DF, que necessitam utilizar o transporte público do DF (Ex: circular), podem receber as duas opções (dinheiro e crédito no cartão DFTrans).",
        "Após a solicitação de vale transporte ou solicitações de alteração de reajuste da tarifa, os pagamentos dos valores devidos serão efetuados em até 15 dias.",
        "Colaboradores em teletrabalho não tem direito ao VT, caso volte ao presencial, deverá seguir as regras e o procedimento de solicitação.",
      ],
      vModel: {
        localidade: null,
        possuiCartao: null,
        comprovanteResidencia: null,
        trajeto: null,
        linha: null,
        valor: null,
        cartao: null,
        informativoReajuste: null,
      },
      showMensagemTrajeto: false,
      headerTable: [
        {
          text: "Trajeto",
          value: "trajeto",
          width: "40%",
          sortable: false,
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "Linha e Empresa",
          value: "linhaEmpresa",
          width: "40%",
          sortable: false,
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "Valor",
          value: "valor",
          width: "20%",
          sortable: false,
          formatter: (x) => (x ? formatCurrency(x) : "-"),
        },
        {
          text: "Ação",
          value: "acao",
          width: "10%",
          sortable: false,
          align: "center",
        },
      ],
      trajetos: [],
      loader: false,
      isAlteracaoTrajeto: false,
      isReajusteValor: false,
    };
  },
  methods: {
    removerMascara,
    solicitar() {
      const vm = this;
      if (!vm.$refs["form"].validate() || !vm.trajetos.length > 0) {
        vm.showMensagemTrajeto = vm.trajetos.length > 0 ? false : true;
        return;
      }
      vm.loader = true;
      vm.showMensagemTrajeto = false;
      api
        .post(
          `/beneficios/pedido/vale?tipoBeneficio=${TipoBeneficioEnum.VALE_TRANSPORTE}`,
          vm.montarPedido()
        )
        .then(async (res) => {
          const documento = vm.isAlteracaoTrajeto
            ? vm.vModel.comprovanteResidencia
            : vm.isReajusteValor
            ? vm.vModel.informativoReajuste
            : null;
          await vm
            .salvarDocumento(res.data.id, documento)
            .catch(() =>
              vm.mensagemErro(
                "Erro ao salvar " + vm.isAlteracaoTrajeto
                  ? "comprovante de residência "
                  : vm.isReajusteValor
                  ? "informativo de reajuste"
                  : "documento"
              )
            );
          await vm.$emit("sucesso");
          vm.fecharModal();
        })
        .catch((e) => vm.mensagemErro(e.message))
        .finally(() => (vm.loader = false));
    },
    async adicionarTrajeto() {
      const vm = this;
      if (!vm.$refs["formTrajeto"].validate()) return;
      let trajeto = {
        trajeto: vm.vModel.trajeto,
        linhaEmpresa: vm.vModel.linha,
        valor: parseFloat(removerMascara(vm.vModel.valor)),
        tipoRecebimento: vm.vModel.localidade == "Outros Estados" ? 0 : 1,
        numCartao: vm.vModel.cartao,
      };
      await vm.trajetos.push(trajeto);
      vm.showMensagemTrajeto = false;
      vm.resetarFormTrajeto();
    },

    async resetarFormTrajeto() {
      const vm = this;
      vm.$refs["formTrajeto"].reset();
    },
    async fecharModal() {
      const vm = this;
      await vm.$refs["form"].reset();
      await vm.resetarFormTrajeto();
      await Object.assign(vm.$data, vm.$options.data());
      vm.show = false;
    },
    montarPedido() {
      const vm = this;
      let pedido = {
        beneficioAnterior: vm.beneficio,
        status: 0,
        tipoMudanca: {
          id: 1,
        },
        pedidoValeTransporte: vm.trajetos,
      };
      return pedido;
    },
    salvarDocumento(idPedido, element) {
      const vm = this;
      let anexoFormData = new FormData();
      anexoFormData.append("arquivo", element);
      anexoFormData.append("nomeArquivo", element.name);
      anexoFormData.append("formatoArquivo", element.type);
      anexoFormData.append("codDominio", vm.isAlteracaoTrajeto ? 706 : 937);
      return api.post(`beneficios/documento-pedido/${idPedido}`, anexoFormData);
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        const vm = this;
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 1rem 3rem !important;
}

.input-field::v-deep .v-input__slot {
  background-color: #ddd !important;
}

.row-trajeto {
  text-align: left;
  font-weight: 600;
  padding: 1rem 0;
}

#btn-excluir {
  background-color: #d91727 !important;
  color: #fff !important;
  font-weight: 700;
  border-radius: 12px;
}

#mensagemTrajeto {
  color: #ff5252;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0.5rem 0 0 1rem;
}
</style>

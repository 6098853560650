<template>
  <div>
    <loader :loader-primario="loader"></loader>

    <alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
      v-on:exclusao="excluir()"
    ></alerta>

    <v-container>
      <div class="card">
        <div class="inicio-titulo">
          <v-img
            class="icone-titulo"
            src="../../assets/images/icone/espaco-colaborador/icone_ferias.svg"
          ></v-img>
          <h1 class="titulo">Minhas férias</h1>
        </div>

        <v-tabs v-model="tab" grow hide-slider show-arrows>
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            href="#tab-1"
            :class="tab == 'tab-1' ? 'tab tab-active' : 'tab'"
            >Solicitação de Férias</v-tab
          >
          <v-tab
            href="#tab-2"
            :class="tab == 'tab-2' ? 'tab tab-active' : 'tab'"
            >Aprovadas / Remarcar</v-tab
          >
          <v-tab
            href="#tab-3"
            :class="tab == 'tab-3' ? 'tab tab-active' : 'tab'"
            >Histórico</v-tab
          >
        </v-tabs>

        <!-- Tabs -->
        <v-tabs-items v-model="tab" touchless class="mt-5">
          <!-- Solicitação de Férias -->
          <v-tab-item value="tab-1">
            <v-row class="row-divider-botao">
              <v-divider class="divider"></v-divider>
              <v-btn
                @click="openModalSolicitacao(false, null)"
                class="botao-principal"
              >
                <v-icon small class="mr-2">fas fa-circle-plus</v-icon>
                Solicitar Férias
              </v-btn>
            </v-row>

            <v-data-table
              :headers="cabecalhoSolicitacoes"
              :items="solicitacoes"
              :page.sync="pagSolicitacoes.page"
              :items-per-page="perPage"
              hide-default-footer
              mobile-breakpoint="770"
              no-data-text="Você não possui solicitação de férias"
              @page-count="pagSolicitacoes.pageCount = $event"
            >
              <template #[`item.status`]="{ item }">
                <div :class="status(item.status)">{{ item.status }}</div>
              </template>

              <template #[`item.acoes`]="{ item }">
                <div class="acoes-tabela">
                  <v-tooltip top v-if="item.status === 'PENDENTE'">
                    <template #activator="{ on }">
                      <v-img
                        @click="excluir(item.id)"
                        v-on="on"
                        class="imagem-acao"
                        src="@/assets/images/icone/espaco-colaborador/icone_reprovar.svg"
                      ></v-img>
                    </template>
                    <span>Cancelar</span>
                  </v-tooltip>
                  <v-tooltip top v-else-if="item.status === 'REJEITADA'">
                    <template #activator="{ on, attrs }">
                      <v-icon
                        @click="mensagem('Motivo da Rejeição', item.comentario)"
                        class="icone-acao"
                        v-bind="attrs"
                        v-on="on"
                      >
                        fa-solid fa-eye
                      </v-icon>
                    </template>
                    <span>Motivo Rejeição</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
            <v-row justify="center">
              <v-col cols="auto">
                <v-pagination
                  prev-icon="fa-light fa-chevron-left"
                  next-icon="fa-light fa-chevron-right"
                  v-model="pagSolicitacoes.page"
                  :length="pagSolicitacoes.pageCount"
                  circle
                  dense
                  solo
                ></v-pagination>
              </v-col>
            </v-row>

            <!-- Regras para Solicitações -->
            <div class="regras-solicitacao">
              <h3>Regras para solicitações</h3>
              <h4>
                <v-icon>fas fa-caret-right</v-icon>
                Quando posso agendar minhas férias?
              </h4>
              <p>
                As férias são concedidas por ato do empregador após cada período
                de 12 (doze) meses de vigência do contrato de trabalho.
                <br />
                A partir desse prazo, o colaborador poderá solicitar o período
                de férias desejado. Lembrando, o pedido passa por análise do
                gestor imediato para aprovação ou cancelamento. O prazo para
                marcação pelo empregado e aprovação do gestor deve ser com pelo
                menos 45 dias de antecedência para o início das férias.
              </p>
              <h4>
                <v-icon>fas fa-caret-right</v-icon>
                Posso iniciar minhas férias em qualquer dia da semana?
              </h4>
              <p>
                Não. Solicitações com data de início que precedam até 2 dias um
                feriado ou final de semana deverá ser justificado.
              </p>
              <h4>
                <v-icon>fas fa-caret-right</v-icon>
                Posso dividir minhs férias?
              </h4>
              <p>
                É possível dividir as férias em três períodos. Um dos períodos
                precisa ter pelo menos 14 dias e os outros dois períodos não
                podem ter menos de 5 dias.
              </p>
              <h4>
                <v-icon>fas fa-caret-right</v-icon>
                Posso pedir adiantamento do 13º salário? Como será o pagamento?
              </h4>
              <p>
                Sim, o adiantamento do 13º salário pode ser solicitado junto com
                as férias. O pagamento é realizado no mesmo dia do pagamento das
                férias e será metade do seu salário contratual.
              </p>
              <h4>
                <v-icon>fas fa-caret-right</v-icon>
                Onde tenho acesso ao recibo do adiantamento do 13º salário?
              </h4>
              <p>
                O recibo do adiantamento do 13º salário é disponibilizado no
                Nossa Hepta, junto com o recibo do pagamento mensal. Importante
                destacar que ele é separado do recibo de férias, apesar do
                pagamento ser feito no mesmo dia.
              </p>

              <p class="mais-informacoes">
                Para mais informações acerca de seus direitos de férias
                <v-btn
                  target="_blank"
                  class="botao-cliqueaqui"
                  href="https://www.jusbrasil.com.br/topicos/10754675/artigo-130-do-decreto-lei-n-5452-de-01-de-maio-de-1943"
                >
                  Clique aqui
                </v-btn>
              </p>
            </div>
          </v-tab-item>

          <!-- Aprovadas / Remarcar -->
          <v-tab-item value="tab-2">
            <v-data-table
              :headers="cabecalhoAprovadaRemarcada"
              :items="historicoFerias"
              :page.sync="pagAprovadaRemarcada.page"
              :items-per-page="perPage"
              hide-default-footer
              no-data-text="Você ainda não possui solicitações aprovadas"
              mobile-breakpoint="770"
              @page-count="pagAprovadaRemarcada.pageCount = $event"
            >
              <template #[`item.acoes`]="{ item }">
                <div class="acoes-tabela">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        :disabled="isDisabledBtnRemarcar(item)"
                        v-bind="attrs"
                        v-on="on"
                        @click="openModalSolicitacao(true, item)"
                        class="icone-acao"
                      >
                        fas fa-pen-to-square
                      </v-icon>
                    </template>
                    <span>Remarcar Férias</span>
                  </v-tooltip>

                  <v-tooltip v-if="!item.codRecibo" top>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        @click="
                          (modalRecibo.show = true),
                            (modalRecibo.codFerias = item.codFerias)
                        "
                        v-bind="attrs"
                        v-on="on"
                        class="icone-acao"
                      >
                        fas fa-file-lines
                      </v-icon>
                    </template>
                    <span>Recibo de Férias</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
            <v-row justify="center" class="mt-2">
              <v-col cols="auto">
                <v-pagination
                  prev-icon="fa-light fa-chevron-left"
                  next-icon="fa-light fa-chevron-right"
                  v-model="pagAprovadaRemarcada.page"
                  :length="pagAprovadaRemarcada.pageCount"
                  circle
                  dense
                  solo
                ></v-pagination>
              </v-col>
            </v-row>
          </v-tab-item>

          <!-- Histórico -->
          <v-tab-item value="tab-3">
            <v-select
              :items="historicoItems"
              v-model="historicoSelect"
              dense
              placeholder="Histórico de férias"
              outlined
              label="Histórico de férias"
              class="input-field mx-4"
            >
              <template slot="label">
                <span class="input-field-text">Histórico de Férias</span>
              </template>
              <template slot="append">
                <v-icon class="input-field-text">fas fa-caret-down</v-icon>
              </template>
            </v-select>

            <div v-if="historicoSelect == 'Ferias'">
              <h1 class="borda">Férias</h1>
              <v-select
                :items="periodo(historico.filtros.periodoAquisitivo)"
                class="input-field"
                v-model="historico.filtros.vModel"
                outlined
                dense
                placeholder="Período Aquisitivo"
                outlinedclearable
              >
                <template slot="label">
                  <span class="input-field-text">Período Aquisitivo</span>
                </template>
                <template slot="append">
                  <v-icon class="input-field-text">fas fa-caret-down</v-icon>
                </template>
              </v-select>

              <v-data-table
                :headers="cabecalhoHistoricoFerias"
                :items="historico.ferias"
                :search="historico.filtros.vModel"
                :page.sync="pagHistoricoFerias.page"
                :items-per-page="perPage"
                hide-default-footer
                mobile-breakpoint="770"
                no-data-text="Você ainda não possui histórico de férias"
                @page-count="pagHistoricoFerias.pageCount = $event"
              ></v-data-table>
              <v-row justify="center" class="mt-2">
                <v-col cols="auto">
                  <v-pagination
                    prev-icon="fa-light fa-chevron-left"
                    next-icon="fa-light fa-chevron-right"
                    v-model="pagHistoricoFerias.page"
                    :length="pagHistoricoFerias.pageCount"
                    circle
                    dense
                    solo
                  ></v-pagination>
                </v-col>
              </v-row>
            </div>

            <div v-if="historicoSelect == 'Solicitacoes'">
              <h1 class="borda">Solicitações</h1>
              <v-select
                :items="periodo(historico.filtros.periodoAquisitivo)"
                class="input-field"
                v-model="historico.filtros.vModel"
                dense
                placeholder="Período Aquisitivo"
                outlined
                label="Período Aquisitivo"
                clearable
              >
                <template slot="label">
                  <span class="input-field-text">Período Aquisitivo</span>
                </template>
                <template slot="append">
                  <v-icon class="input-field-text">fas fa-caret-down</v-icon>
                </template>
              </v-select>

              <v-data-table
                :headers="cabecalhoHistoricoSolicitacoes"
                :items="historico.solicitacoes"
                :search="historico.filtros.vModel"
                :page.sync="pagHistoricoSolicitacoes.page"
                :items-per-page="perPage"
                hide-default-footer
                mobile-breakpoint="770"
                no-data-text="Você ainda não possui solicitações"
                @page-count="pagHistoricoSolicitacoes.pageCount = $event"
              >
                <template #[`item.status`]="{ item }">
                  <div :class="status(item.status)">{{ item.status }}</div>
                </template>
                <template #[`item.detalhes`]="{ item }">
                  <v-tooltip top v-if="item.status === 'REJEITADA'">
                    <template #activator="{ on, attrs }">
                      <v-btn
                        color="#278b97"
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="mensagem('Motivo da Rejeição', item.comentario)"
                      >
                        <v-icon color="white" x-small>fa-solid fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Motivo Rejeição</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <v-row justify="center" class="mt-2">
                <v-col cols="auto">
                  <v-pagination
                    prev-icon="fa-light fa-chevron-left"
                    next-icon="fa-light fa-chevron-right"
                    v-model="pagHistoricoSolicitacoes.page"
                    :length="pagHistoricoSolicitacoes.pageCount"
                    circle
                    dense
                    solo
                  ></v-pagination>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>

    <modal-solicitacao-ferias
      v-model="modalSolicitacao.show"
      :isRemarcacao="modalSolicitacao.isRemarcacao"
      :periodoInfo="
        modalSolicitacao.isRemarcacao ? periodoInfoRemarcacao : periodoInfo
      "
      :feriasRemarcacao="modalSolicitacao.feriasRemarcacao"
      :solicitacoes="solicitacoes"
      @atualizarInformacoes="atualizarInformacoes"
    />

    <modal-recibo-ferias
      v-model="modalRecibo.show"
      :codFerias="modalRecibo.codFerias"
    />
  </div>
</template>

<script>
import Vue from "vue";
import loader from "@/components/Loader.vue";
import alerta from "@/components/Alerta.vue";
import api from "@/services/Api.js";
import modalMixin from "@/mixins/modalMixin";
import ModalSolicitacaoFerias from "@/components/EspacoDoColaborador/Ferias/ModalSolicitacaoFerias.vue";
import DateUtils, { stringBRToDate } from "@/utils/DateUtils";
import ModalReciboFerias from "@/components/EspacoDoColaborador/Ferias/ModalReciboFerias.vue";

Vue.use(DateUtils);

export default {
  components: {
    loader,
    alerta,
    ModalSolicitacaoFerias,
    ModalReciboFerias,
  },
  mixins: [modalMixin],
  name: "ferias",
  data() {
    return {
      loader: false,
      tab: null,
      paths: {
        periodoInfoRemarcacao: "meusdados/ferias/periodo-info-remarcacao",
      },
      modalRecibo: {
        show: false,
        codFerias: null,
      },
      modalSolicitacao: {
        show: false,
        isRemarcacao: false,
        feriasRemarcacao: null,
      },
      ferias: [],
      solicitacoes: [],
      historico: {
        ferias: [],
        solicitacoes: [],
        filtros: {
          periodoAquisitivo: [],
          vModel: "",
        },
      },
      periodoInfo: {},
      periodoInfoRemarcacao: {},
      cabecalhoSolicitacoes: [
        { text: "Período Aquisitivo", value: "dtPeriodoAquisitivo" },
        { text: "Período Solicitado", value: "dtPeriodoSolicitado" },
        { text: "Abono", value: "abono", align: "center" },
        {
          text: "Adiantamento 13º",
          value: "adiantamentoDecimoTerceiro",
          align: "center",
        },
        { text: "Status", value: "status", align: "center" },
        { text: "Ações", value: "acoes", align: "center", sortable: false },
      ],
      historicoSelect: null,
      historicoItems: [
        { text: "Histórico de Solicitações", value: "Solicitacoes" },
        { text: "Histórico de Férias", value: "Ferias" },
      ],
      cabecalhoHistoricoFerias: [
        { text: "Período Aquisitivo", value: "periodoAquisitivo" },
        { text: "Início", value: "inicio", filterable: false, align: "center" },
        { text: "Fim", value: "fim", filterable: false, align: "center" },
        { text: "Total de dias", value: "totalDiasFerias", align: "center" },
        { text: "Abono", value: "abono", align: "center" },
        { text: "Adiantamento 13º", value: "adiantamento_13", align: "center" },
        { text: "Remarcado", value: "remarcada", align: "center" },
      ],
      cabecalhoAprovadaRemarcada: [
        { text: "Período Aquisitivo", value: "periodoAquisitivo" },
        { text: "Início", value: "inicio", align: "center" },
        { text: "Fim", value: "fim", align: "center" },
        { text: "Total de dias", value: "totalDiasFerias" },
        { text: "Abono", value: "abono", align: "center" },
        { text: "Adiantamento 13º", value: "adiantamento_13", align: "center" },
        { text: "Ações", value: "acoes", align: "center", sortable: false },
      ],
      cabecalhoHistoricoSolicitacoes: [
        { text: "Período Aquisitivo", value: "dtPeriodoAquisitivo" },
        {
          text: "Período Solicitado",
          value: "dtPeriodoSolicitado",
          filterable: false,
        },
        { text: "Abono", value: "abono", align: "center" },
        {
          text: "Adiantamento 13º",
          value: "adiantamentoDecimoTerceiro",
          align: "center",
        },
        { text: "Remarcado", value: "remarcado", align: "center" },
        { text: "Status", value: "status", align: "center" },
        {
          text: "Detalhes",
          value: "detalhes",
          align: "center",
          sortable: false,
        },
      ],
      statusEnum: {
        PENDENTE: {
          parsed: "Pendente",
          variante: "warning",
          classe: "warning",
        },
        REJEITADA: {
          parsed: "Rejeitada",
          variante: "danger",
          classe: "danger",
        },
        APROVADA: {
          parsed: "Aprovada",
          variante: "success",
          classe: "success",
        },
        CANCELADA: {
          parsed: "Cancelada",
          variante: "danger",
          classe: "danger",
        },
        CANCELADA_PELO_ADMINISTRATIVO: {
          parsed: "Cancelada pelo administrativo",
          variante: "danger",
          classe: "danger",
        },
      },
      pessoaCod: null,
      historicoFerias: [],
      perPage: 10,
      pagSolicitacoes: {
        page: 1,
        pageCount: 0,
      },
      pagAprovadaRemarcada: {
        page: 1,
        pageCount: 0,
      },
      pagHistoricoFerias: {
        page: 1,
        pageCount: 0,
      },
      pagHistoricoSolicitacoes: {
        page: 1,
        pageCount: 0,
      },
    };
  },
  watch: {
    historicoSelect(e) {
      this.historico.filtros.vModel = "";

      if (e == "Solicitacoes") {
        this.buscarHistoricoSolicitacoes();
      } else if (e == "Ferias") {
        this.buscaHistoricoFerias();
      }
    },
  },
  methods: {
    formatarData(data) {
      if (!data) return null;
      if (data == "Sem data") {
        return data;
      }
      const [ano, mes, dia] = data.includes("-")
        ? data.split("-")
        : data.split("/");
      return `${dia}/${mes}/${ano}`;
    },
    status(e) {
      if (e == "APROVADO" || e == "APROVADA") {
        return "status-tabela status-aprovado";
      } else if (e == "REJEITADA") {
        return "status-tabela status-cancelado";
      } else {
        return "status-tabela status-pendente";
      }
    },
    excluir(idExclusao) {
      const vm = this;
      //solicitou exclusao mas ainda não houve a confirmação
      if (!vm.exclusaoConfirmada) {
        vm.confirmarExclusao("Deseja confirmar a exclusão?", idExclusao);
      }
      //Alerta confirmou exclusão, pegar id de exclusao salvo no mixin
      //exclusaoConfirmada é o id de exclusao salvo no mixin
      else {
        idExclusao = vm.exclusaoConfirmada;
        vm.fecharModal();
        vm.loader = true;

        api
          .post(`meusdados/ferias/excluir/`, {
            codPessoa: vm.pessoaCod,
            codSolicitacao: idExclusao,
          })
          .then((response) => {
            vm.loader = false;
            vm.mensagemSucesso(response.data);
          })
          .catch((e) => {
            vm.mensagemErro(e.message);
          })
          .finally(() => {
            vm.loader = false;
            vm.atualizarInformacoes();
          });
      }
    },
    montarObjetoFerias(f) {
      var feriasMontada = {
        periodoAquisitivo:
          this.formatarExibicaoData(f.pa_data_inicio) +
          " à " +
          this.formatarExibicaoData(f.pa_data_fim),
        inicio:
          f.f_dta_inicio !== null
            ? this.formatarExibicaoData(f.f_dta_inicio)
            : null,
        fim:
          f.f_dta_fim !== null ? this.formatarExibicaoData(f.f_dta_fim) : null,
        totalDiasFerias: f.totalDiasFerias,
        abono: f.ind_abono_pecuniario ? "Sim" : "Não",
        totalDiasAbono: f.totalDiasAbono,
        adiantamento_13: f.ind_adiantamento_13 ? "Sim" : "Não",
        lotacao: f.lotacao,
        codFerias: f.cod_ferias,
        codRecibo: f.idRecibo,
        statusRecibo: f.statusRecibo,
        codSolicitacao: f.codSolicitacao,
        remarcada: f.remarcada ? "Sim" : "Não",
      };
      return feriasMontada;
    },
    periodo(ObjectoPeriodo) {
      if (ObjectoPeriodo) {
        return Array.from(ObjectoPeriodo);
      }
    },
    buscarPeriodoInfo() {
      const vm = this;
      api
        .get(`meusdados/ferias/periodo-info`)
        .then((res) => {
          vm.periodoInfo = res.data;
          vm.periodoInfo.inicioPeriodoConcessivo = vm.formatarData(
            vm.periodoInfo.inicioPeriodoConcessivo
          );
          vm.periodoInfo.fimPeriodoConcessivo = vm.formatarData(
            vm.periodoInfo.fimPeriodoConcessivo
          );
          vm.diasDisponiveisProPeriodo = vm.periodoInfo.qtDias;
        })
        .catch((error) => this.mensagemErro(error))
        .finally(() => {
          setTimeout(() => {
            vm.isLoading = false;
          }, 250);
        });
    },
    buscaHistoricoFerias() {
      const vm = this;
      vm.loader = true;
      vm.pagHistoricoFerias.page = 1;

      api
        .get(`meusdados/ferias/historico`)
        .then((response) => {
          vm.historicoFerias = [];
          vm.historico.ferias = [];

          vm.historico.filtros.periodoAquisitivo = new Set();

          let temp = response.data;
          let temp2 = response.data;

          temp = temp.filter((f) => f.remarcada != true);

          if (temp.length > 0)
            temp.forEach((f) => {
              vm.historicoFerias.push(vm.montarObjetoFerias(f));
            });
          if (temp2.length > 0)
            temp2.forEach((f) => {
              var feriasMontada = vm.montarObjetoFerias(f);
              vm.historico.filtros.periodoAquisitivo.add(
                feriasMontada.periodoAquisitivo
              );
              vm.historico.ferias.push(feriasMontada);
            });
          this.reciboPendente();
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          setTimeout(() => {
            vm.loader = false;
          }, 250);
        });
    },
    buscarHistoricoSolicitacoes() {
      const vm = this;
      vm.loader = true;
      vm.historico.filtros.vModel = "";
      vm.pagHistoricoSolicitacoes.page = 1;

      api
        .get(`meusdados/ferias/solicitacoes`)
        .then((res) => {
          vm.historico.solicitacoes = [];
          res.data.forEach((s) => {
            let solicitacaoMontada = vm.montarSolicitacao(s);

            vm.historico.filtros.periodoAquisitivo.add(
              solicitacaoMontada.dtPeriodoAquisitivo
            );
            vm.historico.solicitacoes.push(solicitacaoMontada);
          });
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          setTimeout(() => {
            vm.loader = false;
          }, 250);
        });
    },
    buscaSolicitacoes() {
      const vm = this;
      vm.loader = true;
      let dataHoje = new Date().toISOString().split("T")[0];
      api
        .get(`meusdados/ferias/solicitacoes?inicio=${dataHoje}`)
        .then((response) => {
          vm.solicitacoes = [];
          let temp = response.data;
          temp = temp.filter((s) => s.remarcado == false);

          if (response.data) {
            temp.forEach((solicitacao) => {
              vm.solicitacoes.push(vm.montarSolicitacao(solicitacao));
            });
          }
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          setTimeout(() => {
            vm.loader = false;
          }, 250);
        });
    },
    montarSolicitacao(solicitacao) {
      const vm = this;
      var solicitacaoMontada = {
        id: solicitacao.id,
        codPessoa: solicitacao.codPessoa,
        codPeriodoAquisitivo: solicitacao.codPeriodoAquisitivo,
        dtPeriodoAquisitivo:
          vm.formatarData(solicitacao.dataInicioPeriodoAquisitivo) +
          " à " +
          vm.formatarData(solicitacao.dataFimPeriodoAquisitivo),
        dtPeriodoSolicitado:
          vm.formatarData(solicitacao.dataInicio) +
          " à " +
          vm.formatarData(solicitacao.dataFim),
        nomePessoa: solicitacao.nomePessoa,
        status: solicitacao.status,
        abono: solicitacao.abono == true ? "Sim" : "Não",
        adiantamentoDecimoTerceiro:
          solicitacao.adiantamentoDecimoTerceiro == true ? "Sim" : "Não",
        justificado: solicitacao.justificado,
        justificativa: solicitacao.justificativa,
        comentario: solicitacao.comentario,
        excluido: solicitacao.excluido,
        remarcado: solicitacao.remarcado ? "Sim" : "Não",
        codFeriasRemarcada: solicitacao.codFeriasRermacada,
        dataCriacao: vm.formatarData(solicitacao.dataCriacao),
        dataInicio: vm.formatarData(solicitacao.dataInicio),
        dataFim: vm.formatarData(solicitacao.dataFim),
        dataInicioAbono: vm.formatarData(solicitacao.dataInicioAbono),
        dataFimAbono: vm.formatarData(solicitacao.dataFimAbono),
        isRejeitada: solicitacao.statusParsed === "rejeitada",
        statusParsed: vm.statusEnum[solicitacao.status].parsed,
        variante: vm.statusEnum[solicitacao.status].variante,
        classe: vm.statusEnum[solicitacao.status].classe,
      };
      vm.pessoaCod = solicitacao.codPessoa;
      return solicitacaoMontada;
    },
    reciboPendente() {
      this.avisoRecibo = this.ferias.find(
        (f) => f.statusRecibo == "ENVIADO" || f.statusRecibo == "INVALIDADO"
      );
    },
    formatarExibicaoData(data) {
      if (!data) return null;
      const ano = data.slice(0, 4);
      const mes = data.slice(5, 7);
      const dia = data.slice(8, 10);

      return dia + "/" + mes + "/" + ano;
    },
    isDisabledBtnRemarcar(item) {
      let dataInicio = stringBRToDate(item.inicio);
      return dataInicio < new Date();
    },
    async isPermitidoRemarcar(ferias) {
      try {
        await api
          .get(`${this.paths.periodoInfoRemarcacao}/${ferias.codFerias}`)
          .then((res) => (this.periodoInfoRemarcacao = res.data));
      } catch (error) {
        this.mensagemErro(error);
        return false;
      }

      this.periodoInfoRemarcacao.inicioPeriodoConcessivo = this.formatarData(
        this.periodoInfoRemarcacao.inicioPeriodoConcessivo
      );
      this.periodoInfoRemarcacao.fimPeriodoConcessivo = this.formatarData(
        this.periodoInfoRemarcacao.fimPeriodoConcessivo
      );

      if (this.periodoInfoRemarcacao.qtDias < 5) {
        this.mensagem(
          "Aviso!",
          "Não possui dias disponíveis para solicitar remarcação. Aguarde o atendimento das solicitações pendentes."
        );
        return false;
      }

      let solicitacao = this.solicitacoes.find(
        (s) =>
          s.status == "PENDENTE" && s.codFeriasRemarcada == ferias.codFerias
      );

      const date = new Date().addDays(15);
      const dataInicio = stringBRToDate(ferias.inicio);

      // Pega a data de 15 dias apartir da data de hoje e verifica com data de inicio das férias
      if (date > dataInicio) {
        this.mensagem(
          "Aviso!",
          "Remarcar somente é permitido 15 dias antes do inicio das férias."
        );
        return false;
      } else if (ferias.remarcada === "Sim") {
        this.mensagem("Aviso!", "Férias já foram remarcadas.");
        return false;
      } else if (solicitacao) {
        this.mensagem(
          "Aviso!",
          "Férias já foram remarcadas. Aguarde o atendimento da solicitação."
        );
        return false;
      }
      return true;
    },
    async openModalSolicitacao(isRemarcacao, ferias) {
      if (isRemarcacao && (await this.isPermitidoRemarcar(ferias))) {
        this.modalSolicitacao.isRemarcacao = true;
        this.modalSolicitacao.show = true;
        this.modalSolicitacao.feriasRemarcacao = ferias;
      } else if (!isRemarcacao) {
        this.modalSolicitacao.isRemarcacao = false;
        this.modalSolicitacao.show = true;
        this.modalSolicitacao.feriasRemarcacao = null;
      }
    },
    atualizarInformacoes() {
      this.buscaHistoricoFerias();
      this.buscaSolicitacoes();
      this.buscarPeriodoInfo();
    },
  },
  async mounted() {
    const vm = this;
    vm.buscaHistoricoFerias();
    vm.buscaSolicitacoes();
    vm.buscarPeriodoInfo();
  },
};
</script>

<style scoped>
.row-divider-botao {
  margin: 0 0 1rem;
}

.divider {
  background-color: #278b97;
  margin: 1.25rem;
}

.imagem-acao {
  max-width: 1.75rem;
  cursor: pointer;
  outline: none;
}

.borda {
  margin: 1rem 0;
  border-left: 0.325rem solid #278b97;
  border-radius: 0.325rem;
  padding: 0 0.75rem;
  color: #278b97;
  background-color: transparent;
}

/* ------------------------------------------------- */
/* Texto Regras de Solicitação */
.regras-solicitacao h3 {
  margin: 1rem 0;
  border-left: 0.325rem solid #278b97;
  border-radius: 0.325rem;
  padding: 0 0.75rem;
  color: #278b97;
  background-color: transparent;
}

.regras-solicitacao h4 {
  color: #e7b814;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.regras-solicitacao h4 .v-icon {
  color: #e7b814;
  font-size: 1rem;
  margin-right: 0.5rem;
}

.regras-solicitacao p {
  padding: 1rem;
}

.mais-informacoes {
  margin: 0;
  color: #278b97 !important;
}

.mais-informacoes .v-btn {
  margin: 0 0.25rem;
  padding: auto;
  color: #278b97 !important;
  border-color: #278b97;
  font-size: 0.875rem;
}

/* ------------------------------------------------- */
/* Status */
.status-aprovado {
  background-color: #32acb9 !important;
}

.status-pendente {
  background-color: #f8ba00 !important;
}

.status-cancelado {
  background-color: #db1826 !important;
}

/* ------------------------------------------------- */
/* Tabs */
.card::v-deep .v-tabs-bar {
  height: auto;
}

.tab {
  background-color: #edefee;
  border: 1px solid #edefee;
  border-radius: 12px;
  text-align: center;
  text-transform: none;
  letter-spacing: 0px;
  font: normal normal bold 16px/20px Montserrat;
  color: #787878 !important;
  margin: 0 1rem;
  padding: 1rem;
  width: 8rem;
}

.tab::before {
  border-radius: 12px;
}

.tab:hover {
  background-color: #eff9fa;
  border: 1px solid #278b97;
  color: #278b97 !important;
}

.tab-active {
  background-color: #278b97;
  border: 1px solid #278b97;
  color: #fff !important;
}
</style>
